import React from "react";
import { graphql } from 'gatsby';
import SEO from "../../components/seo";
import Layout from "../../components/layout";
import Hero from "../../components/hero";
import JobsListing from "../../components/jobs-listing";

export default ({ path, data: { cms: { page_careers, seo } } }) => {
  return (
    <Layout theme="dark">
      <SEO
        title={seo.og_title}
        description={seo.og_description}
        meta={[
          {
            "property": "og:image",
            "content": "/images/og-updated.png"
          }
        ]}
      />

      <Hero heading={page_careers.headline} body={page_careers.hero_body} stack_on_tablet={true}/>
      <JobsListing path={path} />
    </Layout>
  );
}

export const query = graphql`
query careers_companies {
  cms {
    page_careers: entry(id: "7d121740-7519-49b8-b4b5-70c579b9c74c") {
      ... on cms_Entry_Pages_PageCareers {
        id
        title
        headline
        hero_body
      }
    }
    seo: SeoMeta(page_id: "7d121740-7519-49b8-b4b5-70c579b9c74c") {
      og_description
      og_title
      title
    }
  }
}
`